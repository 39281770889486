import React, { useState, useCallback }  from "react";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import ContactForm from "components/ContactForm";

import galeria2 from "../assets/img/descarga1.jpg";
import galeria4 from "../assets/img/descarga3.jpg";
import camion from "../assets/img/camion.jpg";
import tomada2 from "../assets/img/Foto-(26).jpg";
import tomada3 from "../assets/img/Foto-(19).jpg";
import tomada7 from "../assets/img/Foto-(35).jpg";
import tomada8 from "../assets/img/Foto-(39).jpg";
import video1 from "../assets/video/video1.mp4";
import video2 from "../assets/video/video2.mp4";
import video3 from "../assets/video/video3.mp4";

import principal from "../assets/img/principal2.jpg";

// SWIPER //
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, A11y } from 'swiper';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

//import './landing.css'; 

// FIN SWIPER //


export default function Landing() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = [
    {
      src: galeria2
    },
    {
      src: galeria4
    },
    {
      src: tomada2
    },
    {
      src: video1,
      tipo: "video"
    },
    {
      src: video2,
      tipo: "video"
    },
    {
      src: video3,
      tipo: "video"
    },
    {
      src: tomada3
    },
    {
      src: tomada7
    },
    {
      src: tomada8
    }
  ];

  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-48 flex content-center items-end justify-center "
            style={{
              minHeight: "75vh"
            }}>
          <div className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                //backgroundImage: "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')"
                backgroundImage: `url(${principal})`,
              }}>
            <span id="blackOverlay" className="w-full h-full absolute opacity-25 bg-black"></span>
            
          </div>
          <div className="container relative mx-auto pt-24">
              <div className="items-center flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                  <div className="pr-8">
                    <h1 className="text-white font-semibold text-5xl">
                      ECO METAL
                    </h1>
                  </div>
                </div>

              </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Experiencia</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                    Somos una empresa familiar con 30 años de experiencia en el sector
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                    Compra y Venta 
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                    De materiales ferrosos y no ferrosos.
                    Compra y Venta de caño recuperado
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                    <i class="fas fa-truck-loading"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Alquiler
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Alquiler de contenedores para particulares y empresas (entregamos certificado de disposición final)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center mt-8">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mb-6">
                
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Quienes Somos
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                Eco Metal SRL es el principal operador de Materiales Ferrosos en San Juan y cumple con todas las habilitaciones necesarias. La empresa tiene una política ambiental y un sistema de gestión del medio ambiente que cumple con las leyes provinciales y nacionales aplicables.
                </p>
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
                  className="font-bold text-gray-800 mt-8"
                >
                </a>
                <div className="text-grey-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                    <i className="fas fa-cog fa-spin text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">
                    Que hacemos?
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    <li>Servicios de maquinarias: Recolección de Chatarra ferrosa, Destrucción de piezas, Descontaminación, compactación y destrucción de vehículos.</li>
                    <li>Servicios de traslado: de contenedores y jaulas.</li>
                    <li>Acopio y procesamiento de baterías.</li>
                    <li>Reciclaje de plástico.</li>
                    <li>Cartón.</li>
                  </p>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-900">
                  <img
                    alt="..."
                    src={camion}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block"
                      style={{
                        height: "95px",
                        top: "-94px"
                      }}
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-gray-900 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                     Porta contenedores y semi remolques
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                    Contamos con contenedores de 5m3, de 9m3 y jaulas de 20m3.
                    </p>
                  </blockquote>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
              </div>
              <Swiper
              className="swiper-container"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                639: {
                  slidesPerView: 3,
                },
                768:{
                  slidesPerView:4,
                  spaceBetween:50
                }
              }}
               modules={[Navigation, Pagination, A11y]}
               centeredSlides = "true"
               centerInsufficientSlides = "true"
               centeredSlidesBounds = "true"
               
               slidesPerView={3}spaceBetween={50}
               navigation
               onSwiper={(swiper) => console.log(swiper)}
               onSlideChange={() => console.log('slide change')}
                 >
                {
                    photos.map((i, el) => {
                        if(i.tipo!==undefined){
                          return <SwiperSlide><video width="750" height="500" controls >
                            <source src={i.src} type="video/mp4"/>
                            </video></SwiperSlide>;
                        }
                        else{  
                          return <SwiperSlide><img  src={i.src}/></SwiperSlide>;
                        }
                    })
                }
              </Swiper>
              <div>
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
          
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white leading-relaxed">
                  Nuestra Ubicación
                </h2>
                <div className="" class="map-responsive" style={{"overflow":"hidden","padding-bottom":"56.25%","position":"relative","height":0}}>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7313.008917234786!2d-68.59642316967573!3d-31.502167799990946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x968141cc4ef2a6d1%3A0xee571db5bd215507!2sRECUPERADORA%20FERNANDEZ%20-%20ECO%20METAL%20S.R.L.!5e0!3m2!1ses-419!2sar!4v1679319188193!5m2!1ses-419!2sar" width="400" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{"left":0,"top":0,"height":"100%","width":"100%","position":"absolute"}}></iframe>
                </div>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-white">
                  C. Costanera 2720, Chimbas, San Juan
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
              <a href="https://wa.me/5492645720794?text=Hola,%20quisiera%20hacer%20una%20consulta.">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fab fa-whatsapp text-xl"></i>
                </div>
                </a>
                <a href="https://wa.me/5492645720794?text=Hola,%20quisiera%20hacer%20una%20consulta.">
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Whatsapp
                </h6>
                </a>
                <a href="https://wa.me/5492645720794?text=Hola,%20quisiera%20hacer%20una%20consulta.">
                <p className="mt-2 mb-6 text-white">
                  Comunicate con nosotros
                </p>
                </a>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
              <a href="https://www.instagram.com/recuperadora.ecometal/">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fab fa-instagram text-xl"></i>
                </div>
                </a> 
                <a href="https://www.instagram.com/recuperadora.ecometal/">
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Instagram
                </h6>
                </a>
                <a href="https://www.instagram.com/recuperadora.ecometal/">
                <p className="mt-2 mb-4 text-white">
                  Comunicate con nosotros
                </p>
                </a>
              </div>
            </div>
          </div>
        </section>
        <div></div>
        <section className="relative block py-24 lg:pt-0 bg-gray-900">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-32">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                  <div className="flex-auto p-5 lg:p-10">
                  <ContactForm/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()}{" "}ECOMETAL{" "}
              Based on {" "}  
              
              <a
                href="https://www.creative-tim.com"
                className="text-gray-600 hover:text-gray-900"
              >
                 Creative Tim
              </a>.
            </div>
          </div>
        </div>
      </main>
      
      
      {/* This is a JSX comment 
      <Footer />
      */} 
    </>
  );
}
